.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}

.map-full-size {
  height: calc(100vh - 130px);
  margin: -25px;
}


@media screen and (max-width: 768px) {
  .map-full-size {
    width: 100vw;
    height: 100vh;
    margin: -15px -15px 0 -15px;
  }
}
.map-infowindow {
  color: red
}
.map-custom-overlay {
  color: blue
}
.blink {
  animation-name: blinker;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes blinker {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.text-done {
  text-decoration: line-through;
  color: #6a6e72;
}

/* .ant-card-bordered:after {
  content: '';
  position: absolute;
  margin-left: -12px;
  left: 50%;
  width: 22px;
  height: 12px;
  background: url('vertex_white.png')
} */

.arrow_box {
	position: relative;
}
.arrow_box:after, .arrow_box:before {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow_box:after {
	border-color: rgba(255, 255, 255, 0);
	border-top-color: #ffffff;
	border-width: 7px;
	margin-left: -7px;
}
.arrow_box:before {
	border-color: rgba(255, 225, 255, 0);
	border-top-color: #ffffff;
	border-width: 7px;
	margin-left: -7px;
}
.map-card-size {
  width: 100%;
  height: 500px;
}
.ant-upload.ant-upload-drag {
  min-height: 0px;
}